import styled from 'styled-components'

export default styled.div`
  .MuiFormControlLabel-root {
    margin: 0px;
    .MuiFormControlLabel-label {
      font-family: 'Prompt';
      font-size: 18px;
      color: white;
      &.Mui-disabled {
        color: #555;
      }
    }
  }
  .MuiRadio-root {
    color: white;
    padding: 0;
    margin-right: 8px;
    .Mui-checked {
      color: white;
    }
    &.Mui-disabled {
      color: #555;
    }
  }
  .MuiSvgIcon-root {
    width: 16px;
  }
`
