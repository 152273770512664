import React from 'react'
import PropTypes from 'prop-types'
import { Radio, FormControlLabel } from '@material-ui/core'
import RadioInputStyled from './styled'

class RadioInput extends React.PureComponent {
  handleRadioChanged = (e, checked) => {
    if (this.props.onChanged) {
      this.props.onChanged(checked)
    }
  }

  render() {
    return (
      <RadioInputStyled id={this.props.id} data-test={this.props.id} className={this.props.className}>
        <FormControlLabel
          label={this.props.label}
          control={<Radio id={`${this.props.id}-material`} color="default" />}
          onChange={this.handleRadioChanged}
          checked={this.props.checked}
          disabled={this.props.disabled}
        />
      </RadioInputStyled>
    )
  }
}

RadioInput.defaultProps = {
  id: 'radio-input',
  label: '',
  className: '',
  checked: false,
  disabled: false,
}

RadioInput.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  onChanged: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default RadioInput
